import { ActionTypeEnum } from './actionTypes';

export const modalOffAction = {
  type: ActionTypeEnum.MODAL_OFF,
};

export const packageModalOnAction = {
  type: ActionTypeEnum.PACKAGE_MODAL_ON,
};

export const addressModalOnAction = {
  type: ActionTypeEnum.ADDRESS_MODAL_ON,
};
export const warningModalOnAction = {
  type: ActionTypeEnum.WARNING_MODAL_ON,
};

export const DetailModalOnAction = {
  type: ActionTypeEnum.DETAIL_MODAL_ON,
};

export const paymentModalOnAction = (payload: any) => ({
  type: ActionTypeEnum.PAYMENT_MODAL_ON,
  action: payload,
});
