import React from 'react';

import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  font-size: 1.25rem;
  border-radius: calc(12rem / 30);
  border: 1px solid #bababa;
  color: whitesmoke;
  font-weight: 100;
`;

const OrderQuantity = ({ current, total, limit, ...props }: any) => {
  return (
    <Container {...props}>
      {limit ? `${total - current}개 더 담을 수 있어요 💁🏻‍♀️` : `${current}개 잘 포장해드릴게요 🙋🏻‍♀️`}
    </Container>
  );
};

export default OrderQuantity;
