import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';

import Home from './pages/Home';
import Pickup from './pages/Pickup';
import MyOrder from './pages/MyOrder';
import Cart from './pages/Cart';
import Admin from './pages/Admin';
import Modal from './components/Modal/Modal';
import PackageInfo from './components/PackageInfo/PackageInfo';
import PaymentInfo from './components/PaymentInfo/PaymentInfo';
import Layout from './components/Common/Layout/Layout';

function App() {
  const { isPackageModal, isPaymentModal } = useSelector(
    ({ modalReducer }: RootStateOrAny) => modalReducer,
  );
  const isModal = isPackageModal || isPaymentModal;
  return (
    <Router basename='/'>
      <Layout>
        <Routes>
          <Route
            path='/'
            element={<Home />}
          />
          <Route
            path='/pickup'
            element={<Pickup />}
          />
          <Route
            path='/myorder'
            element={<MyOrder />}
          />
          <Route
            path='/cart'
            element={<Cart />}
          />
          <Route
            path='/admin'
            element={<Admin />}
          />
        </Routes>
        {isModal && (
          <Modal bgColor={'black'}>
            {isPackageModal && <PackageInfo />}
            {isPaymentModal && <PaymentInfo />}
          </Modal>
        )}
      </Layout>
    </Router>
  );
}

export default App;
