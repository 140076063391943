import React, { ChangeEventHandler, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

import Question from '../components/Question/Question';
import Input from '../components/Input/Input';
import CartOrderList from '../components/CartOrderList/CartOrderList';
import CartDetailList from '../components/CartDetailList/CartDetailList';
import Button from '../components/Button/Button';
import cartApi from '../api/cart';
import orderApi from '../api/order';
import Modal from '../components/Modal/Modal';
import { paymentModalOnAction, warningModalOnAction } from '../store/actions';
import WarningInfo from '../components/WarningInfo';
import { mq } from '../utils/media';
import { UserModel, OrderModel } from '../model/OrderModel';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
  width: 100%;
  height: calc(100dvh - 6rem);

  ${mq.lg} {
    height: auto;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: end;
  width: 100%;
  max-width: 90vw;
  gap: 2rem;

  ${mq.lg} {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
  }
`;

const StyledTotalPrice = styled.span`
  white-space: nowrap;
`;

const Cart = () => {
  const [orders, setOrders] = useState<OrderModel[]>([]);
  const [detailOrderIndex, setDetailOrderIndex] = useState(0);
  const [orderer, setOrderer] = useState<UserModel>({ name: '', contact: '', password: '' });
  const [isMobile, setIsMobile] = useState(false);
  const { isDetailModal, isWarningModal } = useSelector(
    ({ modalReducer }: RootStateOrAny) => modalReducer,
  );
  const dispatch = useDispatch();
  const getSessionCartList = async () => {
    const res = await cartApi.getCartList();
    setOrders(res.data);
  };

  useEffect(() => {
    try {
      getSessionCartList().then();
    } catch (e) {
      console.error(e);
    }

    const screenWidth = window.innerWidth;
    if (screenWidth <= 1008) setIsMobile(true);
  }, []);

  const handleOrdererChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setOrderer({ ...orderer, [e.target.name]: e.target.value });
  };

  const handleMakeOrder = async () => {
    try {
      if (orders.length && Object.values(orderer).every((el) => el)) {
        await orderApi.makeOrder({ orderer, orders });
        dispatch(paymentModalOnAction({ orderer, orders }));
        setDetailOrderIndex(0);
        setOrders([]);
      } else {
        dispatch(warningModalOnAction);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const totalPrice = React.useMemo(
    () => orders.reduce((acc, cur) => acc + cur.total_price, 0),
    [orders],
  );

  return (
    <Page>
      <FlexContainer>
        <Question label='장바구니 🛒' />
      </FlexContainer>
      <FlexContainer>
        <Input
          name='name'
          desc='주문자명'
          placeholder={'주문자명'}
          trans
          type='text'
          onChange={handleOrdererChange}
        />
        <Input
          name='contact'
          desc='연락처'
          placeholder={'연락처'}
          trans
          type='tel'
          onChange={handleOrdererChange}
        />
        <Input
          name='password'
          desc='임시 비밀번호'
          placeholder='임시 비밀번호'
          trans
          type='password'
          onChange={handleOrdererChange}
        />
        <StyledTotalPrice>{`총 ${totalPrice.toLocaleString()}원`}</StyledTotalPrice>
        <Button
          label='예약하기'
          onClick={handleMakeOrder}
          size='long'
        />
      </FlexContainer>
      <FlexContainer>
        <CartOrderList
          orders={orders}
          setOrders={setOrders}
          setDetailOrderIndex={setDetailOrderIndex}
        />
        {!!detailOrderIndex && !isMobile && (
          <CartDetailList
            order={orders[detailOrderIndex - 1]}
            desserts={orders[detailOrderIndex - 1].Desserts}
          />
        )}
        {!!detailOrderIndex && isMobile && isDetailModal && (
          <Modal bgColor={'black'}>
            <CartDetailList
              order={orders[detailOrderIndex - 1]}
              desserts={orders[detailOrderIndex - 1].Desserts}
            />
          </Modal>
        )}
        {isWarningModal && (
          <Modal bgColor={'black'}>
            <WarningInfo
              info={
                orders.length ? '정보를 모두 입력 후 진행해주세요 :)' : '장바구니가 비었어요 :('
              }
            />
          </Modal>
        )}
      </FlexContainer>
    </Page>
  );
};

export default Cart;
