import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { IoClose } from 'react-icons/io5';

import { modalOffAction } from '../../store/actions';

const ModalWrapper = styled.div<any>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  outline: none;
  z-index: 1000;
`;

const ModalOverlay = styled.div<any>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'black')};
  opacity: 0.5;
  z-index: 999;
`;

const ModalContainer = styled.div<any>`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  border-radius: 1rem;
  color: gray;
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'black')};
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CloseBtn = styled.div`
  border-radius: 0.5rem;
  padding: 0.25rem;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  width: 2.25rem;
  height: 2.25rem;
  font-size: 1.75rem;

  color: gray;
  :hover {
    color: whitesmoke;
    background-color: whitesmoke;
    opacity: 0.8;
  }
`;

const Modal = ({ children, bgColor }: { children: ReactNode; bgColor?: string }) => {
  const dispatch = useDispatch();
  const handleBackgroundClick = (e: any) => {
    if (e.target === e.currentTarget) dispatch(modalOffAction);
  };
  const handleCloseClick = () => {
    dispatch(modalOffAction);
  };

  return (
    <>
      <ModalOverlay bgColor={bgColor} />
      <ModalWrapper
        onClick={handleBackgroundClick}
        tabIndex='-1'
      >
        <ModalContainer
          tabIndex='0'
          bgColor={bgColor}
        >
          <CloseBtn onClick={handleCloseClick}>
            <IoClose />
          </CloseBtn>
          {children}
        </ModalContainer>
      </ModalWrapper>
    </>
  );
};

export default Modal;
