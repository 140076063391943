import React, { ChangeEventHandler, useState } from 'react';
import styled from '@emotion/styled';
import { RootStateOrAny, useSelector } from 'react-redux';

import Question from '../components/Question/Question';
import Input from '../components/Input/Input';
import OrderList from '../components/OrderList/OrderList';
import CartDetailList from '../components/CartDetailList/CartDetailList';
import Button from '../components/Button/Button';
import orderApi from '../api/order';
import Modal from '../components/Modal/Modal';
import { mq } from '../utils/media';
import { OrderModel } from '../model/OrderModel';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
  width: 100%;
  height: calc(100dvh - 6rem);

  ${mq.lg} {
    height: auto;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: end;
  width: 100%;
  max-width: 90vw;
  gap: 2rem;

  ${mq.lg} {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
  }
`;

const MyOrder = () => {
  const [orders, setOrders] = useState<OrderModel[]>([]);
  const [detailOrderIndex, setDetailOrderIndex] = useState(0);
  const [orderer, setOrderer] = useState({ name: '', contact: '', password: '' });
  const [isAdmin, setIsAdmin] = useState(false);
  const { isDetailModal } = useSelector(({ modalReducer }: RootStateOrAny) => modalReducer);

  const handleOrdererChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setOrderer({ ...orderer, [e.target.name]: e.target.value });
  };

  const handleCheckOrder = async () => {
    try {
      const res = await orderApi.getMyOrders(orderer);
      console.log(res.data);
      if (res.data.length) {
        const dateFormattedOrders = res.data.map((order: any) => ({
          ...order,
          pickup_date: order.pickup_date?.slice(0, 10),
          ordered_at: order.ordered_at?.slice(0, 10),
          createdAt: order.createdAt?.slice(0, 10),
          Desserts: order.Desserts.map((el: any) => ({
            id: el.Order_dessert.DessertId,
            name: el.name,
            price: el.price,
            quantity: el.Order_dessert.dessert_quantity,
          })),
        }));
        setOrders(dateFormattedOrders);
        if (dateFormattedOrders[0].User.isAdmin) setIsAdmin(true);
        else setIsAdmin(false);
      }
    } catch (error) {
      setOrders([]);
      console.error(error);
    }
  };

  const showChart = async () => {
    window.location.assign(
      `https://api.loyverse.com/oauth/authorize?client_id=${process.env.REACT_APP_LOYVERSE_CLIENT_ID}&scope=RECEIPTS_READ&response_type=code&redirect_uri=${process.env.REACT_APP_LOYVERSE_REDIRECT_URI}&state=loyverse`,
    );
  };

  return (
    <>
      <Page>
        <FlexContainer>
          <Question
            label='주문 내역 확인 ✅'
            animation={false}
          />
        </FlexContainer>
        <FlexContainer>
          <Input
            name='name'
            desc='주문자명'
            placeholder={'주문자명을 입력해주세요'}
            trans
            type='text'
            onChange={handleOrdererChange}
          />
          <Input
            name='contact'
            desc='연락처'
            placeholder={'연락처를 입력해주세요'}
            trans
            type='tel'
            onChange={handleOrdererChange}
          />
          <Input
            name='password'
            desc='임시 비밀번호'
            placeholder={'임시 비밀번호를 입력해주세요'}
            trans
            type='password'
            onChange={handleOrdererChange}
          />
          <Button
            label='내 주문 확인하기'
            onClick={handleCheckOrder}
            size='long'
          />
          {isAdmin && (
            <Button
              label='판매 현황 확인하기'
              onClick={showChart}
              size='long'
            />
          )}
        </FlexContainer>
        <FlexContainer>
          <OrderList
            orders={orders}
            isAdmin={isAdmin}
            setDetailOrderIndex={setDetailOrderIndex}
          />
        </FlexContainer>
      </Page>
      {isDetailModal && (
        <Modal bgColor={'black'}>
          <CartDetailList
            order={orders[detailOrderIndex]}
            desserts={orders[detailOrderIndex].Desserts}
          ></CartDetailList>
        </Modal>
      )}
    </>
  );
};

export default MyOrder;
