import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { HiCursorClick } from 'react-icons/hi';
import { GrMap } from 'react-icons/gr';
import DaumPostcode, { Address } from 'react-daum-postcode';

import {
  addressModalOnAction,
  modalOffAction,
  packageModalOnAction,
  warningModalOnAction,
} from '../store/actions';
import Dessert from '../components/Dessert/Dessert';
import Question from '../components/Question/Question';
import Button from '../components/Button/Button';
import Input from '../components/Input/Input';
import OrderQuantity from '../components/OrderQuantity/OrderQuantity';
import DatePicker from '../components/Datepicker/Datepicker';

import dessertApi from '../api/dessert';
import cartApi from '../api/cart';
import Modal from '../components/Modal/Modal';
import WarningInfo from '../components/WarningInfo';
import { mq } from '../utils/media';
import { OrderModel } from '../model/OrderModel';

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  height: calc(100dvh - 6rem);
  padding: 2rem;
  gap: 2rem;

  ${mq.lg} {
    height: auto;
    padding: 0;
    flex-direction: column;
    justify-content: start;
    overflow-y: auto;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  ${mq.md} {
    width: 100%;
    padding: 1.5rem;
    gap: 1rem;
  }
`;

const DessertList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 675px;
  gap: 1rem;

  ${mq.md} {
    padding: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    max-height: unset;
  }
`;

const HorizontalFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;

const AddressContainer = styled.div`
  position: relative;
`;

const AddressFinder = styled.div`
  position: absolute;
  width: fit-content;
  height: 1rem;
  right: 1rem;
  top: 1rem;
  * {
    stroke: white;
    cursor: pointer;
  }
`;

function Pickup() {
  const [total, setTotal] = useState(100);
  const [steps, setSteps] = useState({ one: true, two: false, three: false });
  const [orderOptions, setOrderOptions] = useState<OrderModel>({
    type: '방문',
    pickup_date: '',
    recipient: '',
    address: '',
    package: '일반',
    set_price: 0,
    set_quantity: 0,
    order_quantity: 1,
    Desserts: [],
    total_price: 0,
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAddressModal, isWarningModal } = useSelector(
    ({ modalReducer }: RootStateOrAny) => modalReducer,
  );

  const getDessertList = useCallback(async () => {
    const res = await dessertApi.getDesserts();
    const tempDesserts = res.data.map((el: any) => ({ ...el, quantity: 0 }));
    setOrderOptions((prev) => ({ ...prev, Desserts: tempDesserts }));
  }, [setOrderOptions]);

  useEffect(() => {
    console.log('useEffect in pickup');
    try {
      getDessertList().then();
    } catch (e) {
      console.error(e);
    }
  }, [getDessertList]);

  useEffect(() => {
    if (
      (orderOptions.type === '방문' && orderOptions.pickup_date) ||
      (orderOptions.type === '택배' && orderOptions.recipient && orderOptions.address)
    ) {
      setSteps((prev) => ({ ...prev, one: false }));
      if (orderOptions.package) setSteps({ one: false, two: false, three: true });
      else setSteps({ one: false, two: true, three: false });
    } else {
      setSteps({ one: true, two: false, three: false });
    }
  }, [orderOptions, setSteps]);

  const handleAddToCart = async (orderInfo: OrderModel) => {
    let isEnoughInfo: any = { ...orderInfo };
    delete isEnoughInfo.pickup_date;
    delete isEnoughInfo.recipient;
    delete isEnoughInfo.address;
    isEnoughInfo = Object.values(isEnoughInfo).every((el) => !!el);
    const isEnoughDetails =
      !!orderInfo.pickup_date || (!!orderInfo.recipient && !!orderInfo.address);
    if (isEnoughInfo && isEnoughDetails) {
      await cartApi.addToCart(orderInfo);
      navigate('/cart');
    } else {
      dispatch(warningModalOnAction);
    }
  };

  const handlePackageOptionChange = (packageMethod: string) => {
    const tempDesserts = orderOptions.Desserts.map((el) => ({ ...el, quantity: 0 }));
    setOrderOptions({
      ...orderOptions,
      package: packageMethod,
      Desserts: tempDesserts,
      set_quantity: 0,
    });
  };

  const handleSelect: MouseEventHandler<HTMLButtonElement> = (e) => {
    const target = e.target as HTMLButtonElement;
    switch (target.name) {
      case '방문':
        setOrderOptions({ ...orderOptions, type: target.name, address: '' });
        break;
      case '택배':
        setOrderOptions({ ...orderOptions, type: target.name, pickup_date: '' });
        break;
      case '일반':
        setOrderOptions({ ...orderOptions, package: target.name });
        setTotal(100);
        break;
      case '선물(S)':
        handlePackageOptionChange(target.name);
        setTotal(7);
        break;
      case '선물(L)':
        handlePackageOptionChange(target.name);
        setTotal(12);
        break;
      default:
        break;
    }
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setOrderOptions({ ...orderOptions, [e.target.name]: e.target.value });
  };

  const handleDessertClick = (id: number) => {
    if (orderOptions.set_quantity < total) {
      const tempDesserts = orderOptions.Desserts.map((el) =>
        el.id === id ? { ...el, quantity: el.quantity + 1 } : el,
      );
      const tempSetQuantity = tempDesserts.reduce((acc, cur) => acc + cur.quantity, 0);
      const tempSetPrice = tempDesserts.reduce((acc, cur) => acc + cur.price * cur.quantity, 0);
      setOrderOptions({
        ...orderOptions,
        Desserts: tempDesserts,
        set_quantity: tempSetQuantity,
        set_price: tempSetPrice,
        total_price: tempSetPrice,
      });
    }
  };

  const handleDetailClick = () => {
    dispatch(packageModalOnAction);
  };

  const handleComplete = (data: Address) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setOrderOptions({ ...orderOptions, address: fullAddress });
    dispatch(modalOffAction);
    inputRef.current.focus();
  };

  return (
    <Page>
      <Options>
        <Question
          label='1. 주문 방식'
          animation={steps.one}
        />
        <HorizontalFlexContainer>
          <Button
            icon
            trans={true}
            label='방문'
            onClick={handleSelect}
            size='xxlarge'
            backgroundColor={orderOptions.type === '방문' ? 'white' : 'transparent'}
            full
          />
          <Button
            icon
            trans={true}
            label='택배'
            onClick={handleSelect}
            size='xxlarge'
            backgroundColor={orderOptions.type === '택배' ? 'white' : 'transparent'}
            full
          />
        </HorizontalFlexContainer>
        {orderOptions.type === '방문' ? (
          <DatePicker setOrderOptions={setOrderOptions} />
        ) : (
          <>
            <Input
              trans
              placeholder='수령인'
              type='text'
              name='recipient'
              onChange={handleInputChange}
            />
            <AddressContainer>
              <Input
                trans
                value={orderOptions.address}
                placeholder='상세 주소'
                type='text'
                name='address'
                onChange={handleInputChange}
                reference={inputRef}
              />
              {!orderOptions.address && (
                <AddressFinder>
                  <GrMap
                    onClick={() => {
                      dispatch(addressModalOnAction);
                    }}
                  />
                </AddressFinder>
              )}
            </AddressContainer>
          </>
        )}
        <Question
          label='2. 포장 방식'
          animation={steps.two}
        />
        <HorizontalFlexContainer>
          <Button
            icon
            trans={true}
            label='일반'
            onClick={handleSelect}
            size='xlarge'
            backgroundColor={orderOptions.package === '일반' ? 'white' : 'transparent'}
            full
          />
          <Button
            icon
            trans={true}
            label='선물(S)'
            onClick={handleSelect}
            size='xlarge'
            backgroundColor={orderOptions.package === '선물(S)' ? 'white' : 'transparent'}
            full
          />
          <Button
            icon
            trans={true}
            label='선물(L)'
            onClick={handleSelect}
            size='xlarge'
            backgroundColor={orderOptions.package === '선물(L)' ? 'white' : 'transparent'}
            full
          />
        </HorizontalFlexContainer>
        <span onClick={handleDetailClick}>
          선물 이미지 궁금하시면 클릭해주세요! <HiCursorClick />
        </span>
        <Question
          label='3. 디저트 선택 & 장바구니 담기'
          animation={steps.three}
        />
        <OrderQuantity
          current={orderOptions.set_quantity}
          total={total}
          limit={orderOptions.package !== '일반'}
        />
        <Button
          label='장바구니 담기'
          onClick={async () => {
            await handleAddToCart(orderOptions);
          }}
          size='long'
        />
      </Options>
      <DessertList>
        {orderOptions.Desserts.map((dessert) => (
          <Dessert
            key={dessert.id}
            id={dessert.id}
            image={process.env.PUBLIC_URL + dessert.image}
            name={dessert.name}
            price={dessert.price}
            handleDessertClick={handleDessertClick}
            quantity={orderOptions.Desserts.filter((el) => el.id === dessert.id)[0].quantity}
          />
        ))}
      </DessertList>
      {isAddressModal && (
        <Modal bgColor={'white'}>
          <DaumPostcode
            onComplete={handleComplete}
            autoClose={true}
          />
        </Modal>
      )}
      {isWarningModal && (
        <Modal>
          <WarningInfo info={'정보를 모두 입력 후 진행해주세요 :)'} />
        </Modal>
      )}
    </Page>
  );
}

export default Pickup;
