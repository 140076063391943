import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';

import TypingAnimation from '../components/Home/TypingAnimation';
import Indicator from '../components/Common/Animation/Indicator';

gsap.registerPlugin(TextPlugin);

const Page = styled.div`
    width: 100vw;
    height: calc(100vh - 8rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    text-align: center;
    gap: 1.5rem;
`;

const appear = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const StyledLink = styled(Link)`
    position: absolute;
    cursor: pointer;
    color: whitesmoke;
    font-weight: 100;
    border-radius: calc(7.5rem / 20);
    text-decoration: none;
    font-size: 1.3rem;
    width: 12rem;
    opacity: 0;

    animation: ${appear} 3s linear normal;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

function Home() {
  return (
    <Page>
      <TypingAnimation />
      <Container>
        <div style={{ position: 'relative' }}>
          <Indicator />
        </div>
        <StyledLink to='/pickup'>디저트 예약하기</StyledLink>
      </Container>
    </Page>
  );
}

export default Home;
