import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  width: auto;
  height: auto;
`;

const PackageInfo = () => {
  return (
    <Container>
      <img
        alt={'partof-package'}
        style={{ width: '50vw', borderRadius: '1rem' }}
        src={`${process.env.PUBLIC_URL}/images/package_example.jpeg`}
      />
    </Container>
  );
};

export default PackageInfo;
