import { http, HttpResponse } from 'msw';
import { desserts } from './mockData';

/*
 * AWS 비용 증가로 인한 임시 처리 (mockingEndpoint 사용)
 */
const mockingApiEndpoint = 'https://api.partof.online';

export const handlers = [
  http.get(`${process.env.REACT_APP_BASE_URL}/dessert`, () => {
    return HttpResponse.json(desserts);
  }),
  http.get(`${mockingApiEndpoint}/dessert`, () => {
    return HttpResponse.json(desserts);
  }),
];
