import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalStyle from './components/Common/GlobalStyle/GlobalStyle';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { worker } from './mocks/browser';

(async () => {
  /* NOTE:
   * Above if statement is commented because this project is currently running with mock datt in the production mode.
   */

  // if(process.env.NODE_ENV === 'development')
  await worker.start({
    onUnhandledRequest(request, print) {
      /* MSW 로그 생략을 위한 처리 */
    },
  });

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <GlobalStyle />
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
