import React from 'react';

import './Dessert.scss';
import styled from '@emotion/styled';

const StyledDessertItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 9px;
  position: relative;
  cursor: pointer;
`;

const StyledDessertImage = styled.img`
  width: 100%;
  aspect-ratio: 1;
  border-radius: 9px;
  border: 2px solid #bababa;
`;

const StyledDessertCount = styled.div`
  width: 3rem;
  height: 3rem;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  font-weight: 300;
  background-color: #bababa;
  border-radius: 50%;
  right: -1rem;
  top: -1rem;
  z-index: 10;
`;

const StyledDessertInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0.5rem;
`;

const Dessert = ({ id, image, name, price, handleDessertClick, quantity, ...props }: any) => {
  return (
    <StyledDessertItem
      onClick={() => handleDessertClick(id)}
      {...props}
    >
      <StyledDessertImage
        alt={`dessert ${name}`}
        src={image}
      />
      {Boolean(quantity) && <StyledDessertCount>{quantity}</StyledDessertCount>}
      <StyledDessertInfo>
        <span>{name}</span>
        <span>{`${price}원`}</span>
      </StyledDessertInfo>
    </StyledDessertItem>
  );
};

export default Dessert;
