import React, { FC } from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  width: auto;
  height: auto;
`;

interface WarningInfoProps {
  info: string;
}

const WarningInfo: FC<WarningInfoProps> = ({ info }) => {
  return <Container>{info}</Container>;
};

export default WarningInfo;
