import api from './index';
import { OrderModel } from '../model/OrderModel';

const cartApi = {
  addToCart: (order: OrderModel) => api.post('/cart', order, {}),
  removeFromCart: (idx: string) => api.delete(`/cart/${idx}`),
  getCartList: () => api.get('/cart'),
};

export default cartApi;
