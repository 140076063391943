import React, { FC, MouseEventHandler } from 'react';
import { MdOutlineTakeoutDining } from 'react-icons/md';
import { BsBagDash, BsTruck } from 'react-icons/bs';
import { GoGift } from 'react-icons/go';

import './Button.scss';

interface ButtonProps {
  label: string;
  backgroundColor?: string;
  color?: string;
  size: string;
  onClick: MouseEventHandler;
  trans?: boolean;
  full?: boolean;
  type?: string;
  icon?: boolean;
}

const Button: FC<ButtonProps> = ({
  label,
  trans,
  color,
  backgroundColor,
  size,
  icon,
  type,
  full,
  ...props
}) => {
  const mode = `${trans && 'button--transparent'} ${full && 'button--full'}`;
  return (
    <div className='button--container'>
      {['방문', '택배', '일반', '선물(S)', '선물(L)'].includes(label) && (
        <div style={{ fontSize: '0.8rem' }}>{label}</div>
      )}
      <button
        type='button'
        className={['button', `button--${size}`, `button--${type}`, mode].join(' ')}
        style={{ backgroundColor: backgroundColor && backgroundColor, color: color && color }}
        name={label}
        {...props}
      >
        {icon && (
          <div className='button--icon'>
            {label === '방문' && <MdOutlineTakeoutDining fontSize='2rem' />}
            {label === '택배' && <BsTruck fontSize='2rem' />}
            {label === '일반' && <BsBagDash fontSize='2rem' />}
            {label === '선물(S)' && <GoGift fontSize='2rem' />}
            {label === '선물(L)' && <GoGift fontSize='2rem' />}
          </div>
        )}
        {!['방문', '택배', '일반', '선물(S)', '선물(L)'].includes(label) && label}
      </button>
    </div>
  );
};

export default Button;
