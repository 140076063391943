import React, { ChangeEventHandler, FC } from 'react';

import './Input.scss';

interface InputProps {
  name: string;
  type: string;
  value?: string;
  desc?: string;
  placeholder: string;
  pattern?: string;
  trans: boolean;
  reference?: any;
  onChange: ChangeEventHandler;
}

const Input: FC<InputProps> = ({ type, trans, desc, placeholder, pattern, ...props }) => {
  const mode = trans && 'input--transparent';
  return (
    <div className={'input-container'}>
      {desc && <div className='desc'>{desc}</div>}
      <input
        type={type}
        placeholder={placeholder}
        className={['input', mode].join(' ')}
        pattern={pattern}
        ref={props.reference}
        {...props}
      />
    </div>
  );
};

export default Input;
