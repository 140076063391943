import * as React from 'react';
import { ChangeEvent } from 'react';

import Button from '../Button/Button';
import './CartOrderList.scss';
import cartApi from '../../api/cart';
import { useDispatch } from 'react-redux';
import { DetailModalOnAction } from '../../store/actions';
import { OrderModel } from '../../model/OrderModel';

export default function CartOrderList({ orders, setOrders, setDetailOrderIndex }: any) {
  const dispatch = useDispatch();

  const handleDetailClick = (idx: string) => {
    setDetailOrderIndex(idx);
    dispatch(DetailModalOnAction);
  };

  const handleOrderQuantity = (e: ChangeEvent<HTMLInputElement>, index: string) => {
    setOrders(
      orders.map((order: OrderModel, idx: string) =>
        idx === index
          ? {
              ...order,
              order_quantity: Number(e.target.value),
              total_price: order.set_price * Number(e.target.value),
            }
          : order,
      ),
    );
  };

  const handleDeleteClick = async (index: string) => {
    setDetailOrderIndex(0);
    try {
      const res = await cartApi.removeFromCart(index);
      setOrders(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const totalPrice = React.useMemo(
    () => orders.reduce((acc: number, cur: OrderModel) => acc + cur.total_price, 0),
    [orders],
  );

  const totalQuantity = React.useMemo(
    () => orders.reduce((acc: number, cur: OrderModel) => acc + cur.order_quantity, 0),
    [orders],
  );

  return (
    <div className='cartOrderList--container'>
      <table className='cartOrderList'>
        <thead>
          <tr>
            <th align='left'>#</th>
            <th align='center'>주문</th>
            <th align='center'>포장</th>
            <th align='center'>픽업날짜</th>
            <th align='center'>수령인</th>
            <th align='center'>주소</th>
            <th align='center'>세트 가격</th>
            <th align='center'>수량</th>
            <th align='center'>총 금액</th>
            <th align='center'>구성</th>
            <th align='center'></th>
          </tr>
        </thead>
        <tbody>
          {orders.length ? (
            orders.map((order: OrderModel, index: string) => (
              <tr key={index}>
                <td align='left'>{index + 1}</td>
                <td align='center'>{order.type}</td>
                <td align='center'>{order.package}</td>
                <td align='center'>{order.pickup_date}</td>
                <td align='center'>{order.recipient}</td>
                <td align='center'>{order.address}</td>
                <td align='center'>{order.set_price}</td>
                <td align='center'>
                  <input
                    className='setNum'
                    type='number'
                    defaultValue={1}
                    min={0}
                    onChange={(e) => handleOrderQuantity(e, index)}
                  />
                </td>
                <td align='center'>{order.total_price}</td>
                <td align='center'>
                  <Button
                    label='상세'
                    onClick={() => handleDetailClick(index + 1)}
                    size='small'
                    type='detail'
                  />
                </td>
                <td align='center'>
                  <Button
                    backgroundColor='#D34D3A'
                    label='삭제'
                    onClick={() => handleDeleteClick(index)}
                    size='small'
                    type='delete'
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>장바구니에 상품을 담아주세요 :)</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td align='left'>Total</td>
            <td align='center'>{totalQuantity}</td>
            <td align='center'>{totalPrice}</td>
            <td align='center'></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
