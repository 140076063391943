import React from 'react';
import { css, Global } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';
const GlobalStyle = () => {
  console.log('GlobalStyle');

  return (
    <Global
      styles={css`
        ${emotionNormalize}
        html,
        body {
          padding: 0;
          margin: 0;
          font-weight: 100;
          background-color: black;
          background-size: cover;
          color: white;
          * {
            box-sizing: border-box;
            text-decoration: none;
            font-family: 'Noto Sans KR', sans-serif;
          }
        }
      `}
    />
  );
};

export default GlobalStyle;
