import React, { FC } from 'react';
import styled from '@emotion/styled';
import Indicator from '../Common/Animation/Indicator';

const Container = styled.div`
  position: relative;

  display: flex;
  justify-content: start;
  align-items: center;
  color: white;
  margin: 0.5rem 0;
  font-size: 1.2rem;
  > span {
    margin-right: 0.5rem;
    font-weight: 400;
  }

  > span:nth-of-type(2) {
    cursor: pointer;
    margin-left: 2rem;
  }
`;

interface QuestionProps {
  label: string;
  animation?: boolean;
}

const Question: FC<QuestionProps> = ({ label, animation }) => {
  return (
    <Container>
      <span>{label}</span>
      {animation && <Indicator />}
    </Container>
  );
};

export default Question;
