import * as React from 'react';

import './CartDetailList.scss';

export default function CartDetailList({ order, desserts }: any) {
  return (
    <div className='cartDetail--container'>
      <table className='cartDetail'>
        <thead>
          <tr>
            <th align='left'>상품</th>
            <th align='center'>가격</th>
            <th align='center'>수량</th>
            <th align='center'>총 가격</th>
          </tr>
        </thead>
        <tbody>
          {desserts.map(
            (dessert: any) =>
              !!dessert.quantity && (
                <tr key={dessert.id}>
                  <td align='left'>{dessert.name}</td>
                  <td align='center'>{dessert.price}</td>
                  <td align='center'>{dessert.quantity}</td>
                  <td align='center'>{dessert.price * dessert.quantity}</td>
                </tr>
              ),
          )}
        </tbody>
        <tfoot>
          <tr>
            <td align='left'>Total</td>
            <td align='center'>{order.set_quantity}</td>
            <td align='center'>{order.set_price}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
