import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { BsCart4 } from 'react-icons/bs';
import { BiMenu } from 'react-icons/bi';
import { mq } from '../../../utils/media';

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  font-weight: 600;
  background-color: #cccccc;
  position: sticky;

  img {
    min-width: 10rem;
    width: 13rem;
  }
  nav {
    width: 50%;
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      flex: 1 0 0;
      list-style: none;
      text-align: center;
    }
  }
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - 4rem);
  }
  * {
    color: black;
    font-size: 1rem;
  }
  ${mq.lg} {
    height: 5rem;
    a {
      display: none;
    }
  }
`;

const MobileNav = styled.div`
  position: absolute;
  top: 5rem;
  width: 100vw;
  height: calc(100vh - 5rem);
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 10;
  & * {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 3rem;
  }
`;

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isNavOn, setIsNavOn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 1008) setIsMobile(true);
  }, []);

  return (
    <>
      <StyledHeader>
        <div>
          <img
            alt={'partof logo'}
            src={`${process.env.PUBLIC_URL}/images/LOGO_white_crop.png`}
            onClick={() => navigate('/')}
          />
          <nav>
            <ul>
              <li>
                <Link to='/'>Home</Link>
              </li>
              <li>
                <Link to='/pickup'>Order Dessert</Link>
              </li>
              <li>
                <Link to='/myorder'>My Order</Link>
              </li>
            </ul>
          </nav>
          <Link to='/cart'>
            <BsCart4 size={20} />
          </Link>
          {isMobile && (
            <BiMenu
              size={30}
              onClick={() => setIsNavOn(true)}
            />
          )}
        </div>
      </StyledHeader>
      {isNavOn && (
        <MobileNav onClick={() => setIsNavOn(false)}>
          <nav>
            <ul>
              <li>
                <Link to='/'>Home</Link>
              </li>
              <li>
                <Link to='/pickup'>Order Dessert</Link>
              </li>
              <li>
                <Link to='/myorder'>My Order</Link>
              </li>
              <li>
                <Link to='/cart'>Cart</Link>
              </li>
            </ul>
          </nav>
        </MobileNav>
      )}
    </>
  );
};

export default Header;
