import React from 'react';
import LayoutProps from './LayoutProps';
import styled from '@emotion/styled';
import Header from '../Header/Header';

const StyledLayout = styled.div`
  overflow-y: auto;
  height: calc(100vh - 6rem);
`;

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Header />
      <StyledLayout>{children}</StyledLayout>
    </>
  );
};

export default Layout;
