import React from 'react';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import styled from '@emotion/styled';

ChartJS.register(ArcElement, Tooltip, Legend);

const StyledContainer = styled.div`
  width: 100%;
  min-height: 30rem;
`;

interface DoughnutChartProps {
  title: string;
  chartData: any;
}

export function DoughnutChart({ title, chartData }: DoughnutChartProps) {
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: title,
        font: { size: 30 },
      },
    },
  };

  const data = {
    labels: Object.keys(chartData) || ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: Object.values(chartData) || [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <StyledContainer>
      <Doughnut
        data={data}
        options={options}
      />
    </StyledContainer>
  );
}
