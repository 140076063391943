import React from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styled from '@emotion/styled';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
`;

interface LineChartProps {
  title: string;
  chartData: any;
}

export const LineChart = ({ title, chartData }: LineChartProps) => {
  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
        font: { size: 30 },
      },
    },
  };

  const labels = Object.keys(chartData.salesHourlyBasis) || [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ];

  const data = {
    labels,
    datasets: [
      {
        label: '방문 횟수',
        data: Object.values(chartData.customersHourlyBasis) || labels.map(() => Math.random()),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: '매출',
        data: Object.values(chartData.salesHourlyBasis) || labels.map(() => Math.random()),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  return (
    <StyledContainer>
      <Line
        options={options}
        data={data}
      />
    </StyledContainer>
  );
};
