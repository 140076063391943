import React from 'react';
import styled from '@emotion/styled';
import { RootStateOrAny, useSelector } from 'react-redux';
import { OrderModel } from '../../model/OrderModel';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: auto;
  height: auto;
  gap: 0.5rem;
`;

const PaymentInfo = () => {
  const { paymentInfo } = useSelector(({ modalReducer }: RootStateOrAny) => modalReducer);
  const totalPayment = paymentInfo.action.orders.reduce(
    (acc: number, cur: OrderModel) => acc + cur.total_price,
    0,
  );
  const totalDessertQuantity = paymentInfo.action.orders.reduce(
    (acc: number, cur: OrderModel) => acc + cur.set_quantity,
    0,
  );
  const totalSetQuantity = paymentInfo.action.orders.reduce(
    (acc: number, cur: OrderModel) => acc + cur.order_quantity,
    0,
  );
  return (
    <Container>
      <div>
        <strong>주문자</strong> : {paymentInfo.action.orderer.name}님
      </div>
      <div>
        <strong>연락처</strong> : {paymentInfo.action.orderer.contact}
      </div>
      <div>
        <strong>주문 금액</strong> : {totalPayment}원
      </div>
      <div>
        <strong>총 세트 수량</strong> : {totalSetQuantity}개
      </div>
      <div>
        <strong>총 디저트 수량</strong> : {totalDessertQuantity}개
      </div>
      <div>
        주문 감사합니다. 아래 계좌로 입금 부탁드립니다.
        <br /> 주문이 완료되면 메시지를 보내드립니다 :)
      </div>
      <div>
        <strong>계좌 정보</strong> :{' '}
        {process.env.REACT_APP_BANK +
          ' ' +
          process.env.REACT_APP_ACCOUNT +
          ' ' +
          process.env.REACT_APP_ACCOUNT_USERNAME}
      </div>
      <div>
        결제가 완료되지 않은 주문은 매일 자정 모두 삭제됩니다.
        <br /> 삭제된 주문 내역은 다시 확인하실 수 없습니다 :(
      </div>
      <div>현금영수증 발행을 원하시면 별도로 연락 부탁드립니다!</div>
    </Container>
  );
};

export default PaymentInfo;
