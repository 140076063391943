type BreakPoints = {
  sm: number;
  md: number;
  lg: number;
  xl: number;
};

const breakpoints: BreakPoints = {
  sm: 360,
  md: 768,
  lg: 1008,
  xl: 1200,
};

export const mq = Object.keys(breakpoints)
  .map((key: keyof BreakPoints) => [key, breakpoints[key]])
  .reduce((prev: any, [key, breakpoint]) => {
    prev[key] = `@media (max-width: ${breakpoint}px)`;
    return prev;
  }, {});
