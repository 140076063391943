import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { IndicatorProps } from './IndicatorProps';

const scaleOut = keyframes`
    0% {
      transform: scale(0, 0);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
      display: none;
    }
`;

const StyledContainer = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIndicator = styled.div<IndicatorProps>`
  position: absolute;
  width: 2rem;
  height: 2rem;
  border: 2.5px solid #eb5d0b;
  text-align: center;
  line-height: 80px;
  pointer-events: none;
  box-sizing: border-box;
  border-radius: 50px;
  opacity: 1;
  transform: scale(0, 0);
  animation: ${scaleOut} 3s ${({ num }) => num + 0.5 + 's'} infinite linear normal;
`;

const Indicator = () => {
  return (
    <StyledContainer>
      {new Array(3).fill(null).map((_e, i) => (
        <StyledIndicator
          key={i}
          num={i}
          animation
        />
      ))}
    </StyledContainer>
  );
};

export default Indicator;
