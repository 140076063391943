import React, { memo, useEffect, useState } from 'react';
import isMonday from 'date-fns/isMonday';
import { getDate, getMonth, getYear } from 'date-fns';

import './Datepicker.scss';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { OrderModel } from '../../model/OrderModel';

interface DatePickerProps {
  setOrderOptions?: React.Dispatch<React.SetStateAction<OrderModel>>;
  orderOptions?: any;
}

const DatePicker = memo(({ setOrderOptions }: DatePickerProps) => {
  const today = new Date();
  const orderStartDate = today.setDate(today.getDate() + 3);
  const orderEndDate = today.setDate(today.getDate() + 8);

  const [value, setValue] = useState(null);

  useEffect(() => {
    if (value) {
      setOrderOptions((prev) => ({
        ...prev,
        pickup_date: [getYear(value), getMonth(value) + 1, getDate(value)].join('-'),
      }));
    }
  }, [setOrderOptions, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MUIDatePicker
        label='픽업 날짜'
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        shouldDisableDate={isMonday}
        minDate={orderStartDate}
        maxDate={orderEndDate}
        sx={{ width: '100%' }}
      />
    </LocalizationProvider>
  );
});

export default DatePicker;
