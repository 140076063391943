import React, { useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { gsap } from 'gsap';

const StyledAnimation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 80px;

  & > div {
    font-size: 1.5rem;
  }
`;
const TypingAnimation = () => {
  const masterTl = gsap.timeline({ repeat: Infinity });
  const tl = gsap.timeline({ delay: 0.1 });

  const words = useMemo(() => ['PART OF LIFE', 'PART OF EARTH'], []);

  const generateTypingAnimation = useCallback(() => {
    tl.to('.slogan1', { duration: 2, text: words[0] });
    tl.to('.slogan2', { duration: 2, text: words[1] });
    masterTl.add(tl);
  }, [masterTl, tl, words]);

  const clearTypingAnimation = useCallback(() => {
    tl.clear();
  }, [tl]);

  useEffect(() => {
    generateTypingAnimation();

    return () => {
      clearTypingAnimation();
    };
  }, [generateTypingAnimation, clearTypingAnimation]);

  return (
    <StyledAnimation className='animation'>
      <div className='slogan1'></div>
      <div className='slogan2'></div>
    </StyledAnimation>
  );
};

export default TypingAnimation;
