import api from './index';

const salesApi = {
  getLoyverseToken: (authorizationCode: string) =>
    api.post(
      '/sales/token',
      { authorizationCode },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ),
  getLoyverseReceipts: () => api.get('/sales/receipts'),
};

export default salesApi;
