export const desserts = [
  {
    id: 1,
    name: '로투스쿠키',
    price: 2500,
    image: '/images/products/lotus.jpeg',
    createdAt: '2023-12-19T13:58:21.000Z',
    updatedAt: '2023-12-19T13:58:21.000Z',
  },
  {
    id: 2,
    name: '오레오쿠키',
    price: 2400,
    image: '/images/products/oreo.jpeg',
    createdAt: '2023-12-19T13:58:21.000Z',
    updatedAt: '2023-12-19T13:58:21.000Z',
  },
  {
    id: 3,
    name: '콘쿠키',
    price: 2000,
    image: '/images/products/corn.jpeg',
    createdAt: '2023-12-19T13:58:21.000Z',
    updatedAt: '2023-12-19T13:58:21.000Z',
  },
  {
    id: 4,
    name: '캐러멜프레첼쿠키',
    price: 2500,
    image: '/images/products/caramelFrezzle.jpeg',
    createdAt: '2023-12-19T13:58:21.000Z',
    updatedAt: '2023-12-19T13:58:21.000Z',
  },
  {
    id: 5,
    name: '다크초콜릿',
    price: 2500,
    image: '/images/products/darkChoco.jpeg',
    createdAt: '2023-12-19T13:58:21.000Z',
    updatedAt: '2023-12-19T13:58:21.000Z',
  },
  {
    id: 6,
    name: '마카베리쿠키',
    price: 3000,
    image: '/images/products/macaBerry.jpeg',
    createdAt: '2023-12-19T13:58:21.000Z',
    updatedAt: '2023-12-19T13:58:21.000Z',
  },
  {
    id: 7,
    name: '말차오레오쿠키',
    price: 2700,
    image: '/images/products/malchaOreo.jpeg',
    createdAt: '2023-12-19T13:58:21.000Z',
    updatedAt: '2023-12-19T13:58:21.000Z',
  },
];
