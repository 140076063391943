import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { DoughnutChart } from '../components/Doughnut/Doughnut';
import salesApi from '../api/sales';
import { LineChart } from '../components/Chart/Chart';
import { mq } from '../utils/media';

const StyledContainer = styled.div`
  width: 100vw;
  height: auto;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 30% 30%;
  gap: 5%;
  padding: 3rem 0;
  ${mq.sm} {
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }
`;

const Admin = () => {
  const [chartData, setChartData] = useState({ menu: null, time: null });

  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');
  const state = url.searchParams.get('state');

  useEffect(() => {
    (async () => {
      if (code && state === 'loyverse') {
        try {
          await salesApi.getLoyverseToken(code);
        } catch (error) {
          console.error(error);
        }
      }
      const res = await salesApi.getLoyverseReceipts();
      setChartData(res.data.preprocessedData);
    })();
  }, [code, state]);

  return (
    <StyledContainer>
      {chartData.menu && (
        <>
          <DoughnutChart
            title='Coffee'
            chartData={chartData.menu.coffee}
          />
          <DoughnutChart
            title='Dessert'
            chartData={chartData.menu.dessert}
          />
          <DoughnutChart
            title='Beverage'
            chartData={chartData.menu.beverage}
          />
          <DoughnutChart
            title='Tea'
            chartData={chartData.menu.tea}
          />
        </>
      )}
      {chartData.time && (
        <LineChart
          title='시간대별 손님 현황'
          chartData={chartData.time}
        />
      )}
      {/* <LineChart />
      <LineChart /> */}
    </StyledContainer>
  );
};

export default Admin;
