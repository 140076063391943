import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080/'
      : 'https://api.partof.online/',
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
  },
});

export default api;
