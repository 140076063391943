import { ActionTypeEnum } from '../actions/actionTypes';

export interface InitialModalState {
  isPackageModal: boolean;
  isDetailModal: boolean;
  isPaymentModal: boolean;
  isAddressModal: boolean;
  isWarningModal: boolean;
  paymentInfo: any;
}

const initialState: InitialModalState = {
  isPackageModal: false,
  isDetailModal: false,
  isPaymentModal: false,
  isAddressModal: false,
  isWarningModal: false,
  paymentInfo: {},
};

const modalReducer = (prevState = initialState, action: { type: ActionTypeEnum }) => {
  let state;
  switch (action.type) {
    case ActionTypeEnum.PACKAGE_MODAL_ON:
      state = { ...prevState, isPackageModal: true };
      break;
    case ActionTypeEnum.DETAIL_MODAL_ON:
      state = { ...prevState, isDetailModal: true };
      break;
    case ActionTypeEnum.PAYMENT_MODAL_ON:
      state = { ...prevState, isPaymentModal: true, paymentInfo: action };
      break;
    case ActionTypeEnum.ADDRESS_MODAL_ON:
      state = { ...prevState, isAddressModal: true };
      break;
    case ActionTypeEnum.WARNING_MODAL_ON:
      state = { ...prevState, isWarningModal: true };
      break;
    case ActionTypeEnum.MODAL_OFF:
      state = { ...initialState };
      break;
    default:
      state = { ...prevState };
  }
  return state;
};

export default modalReducer;
