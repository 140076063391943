import api from './index';
import { UserModel, OrderInfoModel } from '../model/OrderModel';

const orderApi = {
  makeOrder: (info: OrderInfoModel) => api.post('/order', info),
  getMyOrders: (orderer: UserModel) => api.post('/order/me', orderer),
  completeOrder: (id: string) => api.put('/order', { id: id }),
};

export default orderApi;
