import * as React from 'react';
import { useDispatch } from 'react-redux';

import './OrderList.scss';
import Button from '../Button/Button';
import orderApi from '../../api/order';
import { DetailModalOnAction } from '../../store/actions';
import { OrderModel } from '../../model/OrderModel';

export default function OrderList({
  orders,
  isAdmin,
  setDetailOrderIndex,
}: {
  orders: OrderModel[];
  isAdmin: boolean;
  setDetailOrderIndex: React.Dispatch<React.SetStateAction<number>>;
}) {
  const totalPrice = React.useMemo(
    () => orders.reduce((acc, cur) => acc + cur.total_price, 0),
    [orders],
  );

  const totalQuantity = React.useMemo(
    () => orders.reduce((acc, cur) => acc + cur.order_quantity, 0),
    [orders],
  );
  const dispatch = useDispatch();

  const handleDetailClick = (idx: number) => {
    setDetailOrderIndex(idx);
    dispatch(DetailModalOnAction);
  };

  const completeOrder = async (order: any) => {
    if (order.status === false) {
      await orderApi.completeOrder(order.id);
    }
  };

  return (
    <div className='myOrderList--container'>
      <table className='myOrderList'>
        <thead>
          <tr>
            <th align='left'>#</th>
            <th align='center'>고객</th>
            <th align='center'>연락처</th>
            <th align='center'>주문일자</th>
            <th align='center'>주문</th>
            <th align='center'>포장</th>
            <th align='center'>픽업날짜</th>
            <th align='center'>수령인</th>
            <th align='center'>주소</th>
            <th align='center'>세트 가격</th>
            <th align='center'>수량</th>
            <th align='center'>총 금액</th>
            <th align='center'>구성</th>
            <th align='center'>결제</th>
            <th align='center'>주문</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order: any, index) => (
            <tr
              key={order.id}
              className={order.payment ? 'paid' : ''}
            >
              <td align='left'>{order.id}</td>
              <td align='center'>{order.User.name}</td>
              <td align='center'>{order.User.contact}</td>
              <td align='center'>{order.createdAt}</td>
              <td align='center'>{order.type}</td>
              <td align='center'>{order.package}</td>
              <td align='center'>{order.pickup_date}</td>
              <td align='center'>{order.recipient}</td>
              <td align='center'>{order.address}</td>
              <td align='center'>{order.set_price} </td>
              <td align='center'>{order.order_quantity}</td>
              <td align='center'>{order.total_price}</td>
              <td align='center'>
                <Button
                  label='상세'
                  onClick={() => handleDetailClick(index)}
                  size='small'
                  type='detail'
                />
              </td>
              <td align='center'>{order.payment ? '완료' : '미완료'}</td>
              <td align='center'>
                {isAdmin ? (
                  <Button
                    backgroundColor='#15f301'
                    label='완료'
                    onClick={() => completeOrder(order)}
                    size='small'
                  />
                ) : order.status ? (
                  '준비완료'
                ) : (
                  '준비중'
                )}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td align='left'>Total</td>
            <td align='center'>{totalQuantity}</td>
            <td align='center'>{totalPrice}</td>
            <td align='center'></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
